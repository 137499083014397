import React from 'react';
import {
    isAndroid,
    isIOS,
    isSafari
} from "react-device-detect";
// import { trackPromise } from 'react-promise-tracker';
// import Item from "./components/item"; // Working but without loader
import Item from "./components/itemComp"; // development

import logo from './assets/img/hu_logo_small.png';

import cOneReality from './assets/3d_models/citation_one/citation_one.reality';
import cOneUsdz from './assets/3d_models/citation_one/citation_one.usdz';
import cOneGlb from './assets/3d_models/citation_one/citation_one.glb';

import cSetUsdz from './assets/3d_models/citation_set/Citation_Set.usdz';
import cSetGlb from './assets/3d_models/citation_set/Citation_Set.glb';

import jPartyReality from './assets/3d_models/jbl_partyox/JBL_Partybox.reality';
import jPartyUsdz from './assets/3d_models/jbl_partyox/JBL_Partybox.usdz';
import jPartyGlb from './assets/3d_models/jbl_partyox/JBL_Partybox.glb';

function cOne() {
    if (isAndroid) {
        return "intent://arvr.google.com/scene-viewer/1.0?file="+process.env.REACT_APP_DOMAIN+cOneGlb+"#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;";
    } else if (isIOS && isSafari) {
        return cOneReality;
    } else {
        return cOneUsdz;
    }
}

function cSet() {
    if (isAndroid) {
        return "intent://arvr.google.com/scene-viewer/1.0?file="+process.env.REACT_APP_DOMAIN+cSetGlb+"#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;";
    } else if (isIOS && isSafari) {
        return cSetUsdz;
    } else {
        return cSetUsdz;
    }
}

function jParty() {
    if (isAndroid) {
        return "intent://arvr.google.com/scene-viewer/1.0?file="+process.env.REACT_APP_DOMAIN+jPartyGlb+"#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;";
    } else if (isIOS && isSafari) {
        return jPartyReality;
    } else {
        return jPartyUsdz;
    }
}

function App() {
  return (
      
    <div className="md:w-96 md:my-8 m-auto bg-white py-6 px-8 md:rounded-lg shadow-2xl">
        <img src={logo} alt="Hueman design" className="logo"/>
        <h1 className="text-2xl pb-4">Augmented Reality Catalogue</h1>
        <p>This webpage is served to demo how a user can embed AR product views in Harman and e-commerce sales channels of our partner's websites or app's to let users see incredibly detailed object renderings in a real-world surrounding or with an artificial immersive atmosphere to help sell products through deeper customer engagement.</p>
        <p>Please open this page from your smartphone only.</p>
        <p>Tap on any of the 3D models below, to view the object and place it in AR.</p>
        
        <Item itemObject={cSet()} itemImage="01_Citation_Set_AR_icon_preview.png" iosImage="01_Citation_Set.png" itemDecription="Citation 5.1 home theatre set" itemPrice="$1500" />

        <Item itemObject={cOne()} itemImage="02_Citation_MKII_AR_icon_preview.png" iosImage="02_Citation_MKII.png" itemDecription="Citation One MKII" itemPrice="$150" />
        
        <Item itemObject={jParty()} itemImage="03_Partybox_1000_AR_icon_preview.png" iosImage="03_Partybox_1000.png" itemDecription="JBL Partybox 1000" itemPrice="$300" />
        
        <hr className="mb-6 mt-1" />
        
        <div className="bg-yellow-300 p-2">
            <p className="pb-0 text-gray-600">This JBL Quantum One demo requires Instagramm app installed on your smartphone.</p>
        </div>
        <div className="object pt-4">
            <a href="https://www.instagram.com/ar/266928854357193/?ch=MTljZjc1NjllNGVkODNhNjBhZjRkNDBjODAzYjQ2YzE%3D">
                <img src={require('./assets/img/04_Quantum_One_AR_icon_preview.png')} className="rounded-lg pb-2" alt="Citation Set" />
            </a>
            <p className="description text-gray-500">JBL Quantum One - head tracking experience with a cyberpunk atmosphere</p>
        </div>
    </div>
  );
}
export default App;
