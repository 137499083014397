import React, { useState } from 'react'; //useRef
import {
    isIOS,
    isSafari
} from "react-device-detect";
// import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { css } from "@emotion/core";
import BounceLoader from "react-spinners/BounceLoader";

const override = css`
  display: block;
  margin: -20px auto 0 auto;
  border-color: red;
  position: absolute;
`;

function Item(props) {

    const linkRef = React.createRef();
    const [loading, setLoading] = useState(false);


    function chooseImage() {
        var image="";
        if (isIOS && isSafari) {
            image = props.iosImage;
        } else {
            image = props.itemImage;
        }
        return image;
    }

    function handleClick(e) {
        // e.preventDefault();

        setLoading(true);

        // const href = linkRef.current.getAttribute('href');
        // window.location.href = href;

        setTimeout(() => setLoading(false), 15*1000);

    }

    window.addEventListener('blur', () => {setLoading(false)});
    // document.addEventListener("visibilitychange", () => {setLoading(false)});
    // onClick={(e) => handleClick(e)}

    // const linkElement = document.getElementsByClassName("ar-link");
    // linkElement.addEventListener("message", function (event) {   
    //     if (event.data === "_apple_ar_quicklook_button_tapped") {
    //         alert("Item added to cart!");
    //     }
    // }, false);

    return (
        <div className="object pt-3 pb-2">
            <BounceLoader
                css={override}
                size={170}
                color={"#333"}
                loading={loading}
            />
              
            <a href={props.itemObject+"#applePayButtonType=buy&checkoutTitle="+encodeURI(props.itemDecription)+"&price="+props.itemPrice} rel="ar" ref={linkRef} onClick={(e) => handleClick(e)}>
                <img src={require('../assets/img/'+chooseImage())} className="rounded-lg pb-2" alt={props.itemDecription} />
            </a>
            <p className="description text-gray-500">{props.itemDecription}</p>
        </div>
    )
}

export default Item;

